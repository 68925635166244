.About {
  //background-color: antiquewhite;
  //padding: 80px;
  //background-image: url("./assets/backdrop2.jpg");
  //background-size: 100vw;
  .home-image {
    width: 100vw;
    opacity: 0.85;
    z-index: 99;
    position: relative;
  }

  .about-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    .about-paragraph {
      text-align: left;
      width: 50%;
    }
  }

  .main-img
  {
    position: absolute;
    //top: 0;
    //left: 0;
    width: 30%;
    height: auto;
    z-index: 1;
  }
  .overlay
  {
    position: absolute;
    //top: 60px;
    //left: 80px;
    //top: 0;
    //left: 0;
    opacity: 0.7;
    width: 30%;
    height: 73%;
    z-index: 2;
    transition: 1s ease-in;
  }

}