.Footer {
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 60px;
    background: #e6e7e8;
    font-size: 13px;
    //color: aliceblue;
    box-shadow: 0 -1px 10px 0 rgba(0,0,0,.4);

    .socials {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px;
      //background: darkgoldenrod;
      i {
        font-size: 24px;
        padding: 0 16px;
        color: black;
        &:hover {
          color: #636363;
        }
      }
    }

    .disclaimer {
      text-decoration: underline;
      &:hover {
        cursor: pointer;
        color: #636363;
      }
    }

  }
}