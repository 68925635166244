.NavbarForHome {

   .navigation {
     padding: 20px 40px;
     //background: transparent;
     position: fixed;
     z-index: 2000;
     top: 0;
     left: 0;
     right: 0;
     //color: white;
     font-size: 20px;
     //font-weight: bold;
     font-family: Roboto, sans-serif;
     transition: 0.25s ease-in-out;
     //background: rgba(white, 0.5);

     display: flex;
     justify-content: space-between;
     align-items: center;

     .nav-items {
       display: inline-flex;
       list-style: none;
       margin: 0;
       a {
         color: #636363;
         text-decoration: none;
       }
       .nav-item {
         padding: 8px 16px;
       }
       .nav-item:hover {
         //background: rgba(white, 0.5);
         //transition: background 0.25s ease-in-out;
         //border-radius: 8px;
         color: #222222;
       }
     }
   }

   .navigation-scroll {
     padding: 20px 40px;
     background: white;
     position: fixed;
     z-index: 2000;
     top: 0;
     left: 0;
     right: 0;
     color: #636363;
     font-size: 20px;
     //font-weight: bold;
     font-family: Roboto, sans-serif;
     box-shadow: 0 -3px 9px 0 rgba(0,0,0,.5);
     transition: 0.25s ease-in-out;

     display: flex;
     justify-content: space-between;
     align-items: center;

     .nav-items {
       display: inline-flex;
       list-style: none;
       margin: 0;
       a {
         color: #636363;
         text-decoration: none;
       }
       .nav-item {
         padding: 8px 16px;
       }
       .nav-item:hover {
         color: #222222;

       }
     }
   }
 }