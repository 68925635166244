.Contact {
  .contact-container {

    padding: 10% 24px;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;



    .contact-heading {
      font-size: 55px;
      line-height: 80px;
      font-weight: 700;
      text-align: center;

      position: relative;
      z-index: 10;
      margin-top: 20px;
      margin-bottom: 10px;
      font-family: Roboto, sans-serif;
      color: #636363;
      letter-spacing: 0;
      text-transform: none;
    }

    .contact-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 5%;
    }

    a {
      text-decoration: none;
      &:hover {
        box-shadow: 0 1px 5px 0 #636363;
        border-radius: 8px;
      }
      transition: box-shadow 300ms ease;
      .icon-text {
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #636363;
        padding: 24px;
        border: 1px solid #e6e7e8;
        border-radius: 8px;
        i {
          font-size: 18px;
          margin-right: 16px;
        }
      }
    }
  }
}