.NUShare {
  .nushare-container {
    padding: 10% 24px;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;

    .enlarge-img {
      transform-origin: right;
      transition: transform 0.5s ease-in-out;
      &:hover {
        transform: scale(1.5,1.5);
        transform-origin: right;
        transition: transform 0.5s ease-in-out;
      }
    }


    .chapter-block {
      margin: 10% auto;

      .chapter-pre-label {
        position: relative;
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
        font-family: 'Roboto Mono', sans-serif;
        color: #636363;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;
      }

      .divider {
        width: 20%;
        height: 1px;
        margin: 25px auto;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#ddd), to(#ddd));
        background-image: linear-gradient(180deg, #ddd, #ddd);
      }

      .chapter-heading {
        font-size: 55px;
        line-height: 80px;
        font-weight: 700;
        text-align: center;

        position: relative;
        z-index: 10;
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: Roboto, sans-serif;
        color: #636363;
        //font-size: 55px;
        //line-height: 65px;
        //font-weight: 300;
        //text-align: left;
        letter-spacing: 0px;
        text-transform: none;
      }
    }

    .intro {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .intro-para {

        .work-heading {
          display: block;
          opacity: 1;
          font-family: Roboto, sans-serif;
          font-size: 55px;
          line-height: 65px;
          font-weight: 300;
          text-align: left;
          letter-spacing: 0;
          text-transform: none;
          position: relative;
          z-index: 10;
          margin-top: 20px;
          margin-bottom: 10px;
          color: #636363;
        }

        .work-post-head {
          //margin-left: 15px;
          font-family: 'Roboto Mono', sans-serif;
          font-size: 16px;
          color: #636363;
          line-height: 22px;
          letter-spacing: 0.05em;
          //display: -webkit-box;
          //display: -webkit-flex;
          //display: -ms-flexbox;
          //display: flex;
          //-webkit-box-align: center;
          //-webkit-align-items: center;
          //-ms-flex-align: center;
          //align-items: center;
        }
        .work-subhead {
          margin-top: 0;
          margin-bottom: 25px;
          font-family: Roboto, sans-serif;
          color: #636363;
          font-size: 18px;
          line-height: 34px;
          padding-top: 40px;
        }
        width: 60%;
        padding: 24px 80px;
      }
      .intro-image {
        width: 60%;
        padding: 16px;
        img {
          width: 100%;
        }
      }
    }

    .body-text {
      font-family: Roboto, sans-serif;
      color: #636363;
      font-size: 18px;
      line-height: 34px;
    }

    .problem-statement {

    }

    .storyboard {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 30%;
      }
    }

    .wireframes {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      img, iframe {
        width: 30%;
        padding: 16px;
      }
    }

    .me-fi-prototype {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      img, iframe {
        width: 30%;
        padding: 16px;
      }
    }

  }
}