.Home {

  //background-color: antiquewhite;
  //height: 100vh;
  font-family: "neosans regular", "Helvetica Neue", Helvetica, Arial, Sans-serif;


  .home-image {
    width: 100vw;
    //opacity: 0.85;
    z-index: 99;
    position: relative;

  }

  .intro-section {
    position: center;
    text-align: center;
    padding: 80px;
    margin-bottom: 10px;
.headshot {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #636363;
}


    .name-heading {
      //font-size: 50px;
      //margin-top: 0;
        font-size: 55px;
        line-height: 80px;
        font-weight: 700;
        text-align: center;

        position: relative;
        z-index: 10;
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: Roboto, sans-serif;
        color: #636363;
        letter-spacing: 0;
        text-transform: none;

    }

    .name-subheading {
      //font-size: 20px;

      position: relative;
      -webkit-box-flex: 0;
      -webkit-flex: 0 auto;
      -ms-flex: 0 auto;
      flex: 0 auto;
      font-family: 'Roboto Mono', sans-serif;
      color: #636363;
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.2em;
      text-transform: uppercase;
    }
  }

  .about-description {
    padding: 80px 250px;
    background-color: mintcream;
    text-align: center;
    font-size: 17px;
    font-family: 'Roboto Mono', sans-serif;
    line-height: 30px;
    letter-spacing: 0.15em;
    color: #636363;
    a {
      color: black;
    }
  }

  .kind-words {
    padding: 80px 100px;
    font-size: 17px;
    font-family: 'Roboto Mono', sans-serif;
    text-align: center;

    .name-heading {
      //font-size: 50px;
      //margin-top: 0;
      font-size: 55px;
      line-height: 80px;
      font-weight: 700;
      text-align: center;

      position: relative;
      z-index: 10;
      margin-top: 20px;
      margin-bottom: 10px;
      font-family: Roboto, sans-serif;
      color: #636363;
      letter-spacing: 0;
      text-transform: none;

    }

    .reco-container-container {
      display: flex;
      justify-content: space-around;
    }

    .single-reco-container {
      margin-bottom: 40px;
      padding: 32px;

      text-align: center;
      font-size: 17px;
      //font-family: 'Roboto Mono', sans-serif;
      line-height: 20px;
      letter-spacing: 0.10em;
      color: #636363;

      font-family: 'Crimson Text', sans-serif;


      .recommendation {
        text-align: center;
      }

      .member-company-designation {
        text-transform: uppercase;
        font-size: 12px;
      }
    }

    .member-name {
      a {
        color: black;
      }
    }
  }
}
