.Voice {
  .voice-container {
    padding: 10% 24px;
    max-width: 1220px;
    margin-left: auto;
    margin-right: auto;

    .body-text {
      font-family: Roboto, sans-serif;
      color: #636363;
      font-size: 18px;
      line-height: 34px;
    }

    .chapter-block {
      margin: 10% auto;

      .chapter-pre-label {
        position: relative;
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto;
        font-family: 'Roboto Mono', sans-serif;
        color: #636363;
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;
      }

      .divider {
        width: 20%;
        height: 1px;
        margin: 25px auto;
        background-image: -webkit-gradient(linear, left top, left bottom, from(#ddd), to(#ddd));
        background-image: linear-gradient(180deg, #ddd, #ddd);
      }

      .chapter-heading {
        font-size: 55px;
        line-height: 80px;
        font-weight: 700;
        text-align: center;

        position: relative;
        z-index: 10;
        margin-top: 20px;
        margin-bottom: 10px;
        font-family: Roboto, sans-serif;
        color: #636363;
        //font-size: 55px;
        //line-height: 65px;
        //font-weight: 300;
        //text-align: left;
        letter-spacing: 0px;
        text-transform: none;
      }
    }

    .intro {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .intro-para {
        //background: aqua;
        .work-heading {
          display: block;
          opacity: 1;
          font-family: Roboto, sans-serif;
          font-size: 55px;
          line-height: 65px;
          font-weight: 300;
          text-align: left;
          letter-spacing: 0;
          text-transform: none;
          position: relative;
          z-index: 10;
          margin-top: 20px;
          margin-bottom: 10px;
          color: #636363;
        }

        .work-subhead {
          margin-top: 0;
          margin-bottom: 25px;
          font-family: Roboto, sans-serif;
          color: #636363;
          font-size: 18px;
          line-height: 34px;
          padding-top: 40px;
        }
        width: 80%;
        padding: 24px 80px;
      }
      .intro-image {
        //background: aquamarine;
        width: 70%;
        padding: 16px;
        img {
          width: 100%;
        }
      }
    }

    .goals-container {

    }

    .pain-points-container {

    }

    .prototype {
      width: 400px;
      height: 600px;
      border: 1px solid black;
      border-radius: 10px;
      video {
        border-radius: 10px;
      }
    }
  }
}